<nz-layout class="layout">
	<nz-affix nzOffsetTop="0">
		<nz-header>
			<div class="logo"></div>
			<ul class="top-menu" nz-menu [nzTheme]="'dark'" [nzMode]="'horizontal'" style="line-height: 64px;">
			</ul>
			<nz-dropdown class="lang-switcher" nzTrigger="click">
				<button nz-button nz-dropdown>
					{{translate.currentLang}}
					<i nz-icon nzType="down"></i>
				</button>
				<ul nz-menu nzSelectable>
					<li nz-menu-item *ngFor="let lang of getSelectableLanguages()" (click)="translate.use(lang)">
						{{lang}}</li>
				</ul>
			</nz-dropdown>
		</nz-header>
	</nz-affix>
	<nz-content style="padding:0 50px;">
		<div nz-row>
			<p>{{'intro_text' | translate}}</p>
		</div>

		<div nz-row>
			<div nz-col nzSpan="10">
				<form nz-form [formGroup]="validateForm">
					<nz-form-item>
						<nz-form-label [nzSm]="12" [nzXs]="24" nzRequired nzFor="rows_count">
							{{'rows_count' | translate}}
						</nz-form-label>
						<nz-form-control [nzSm]="12" [nzXs]="24">
							<nz-select formControlName="rows_count" id="rows_count"
								(ngModelChange)="updateModulesTableSettings()">
								<nz-option nzValue="1" nzLabel="1"></nz-option>
								<nz-option nzValue="2" nzLabel="2"></nz-option>
								<nz-option nzValue="3" nzLabel="3"></nz-option>
								<nz-option nzValue="4" nzLabel="4"></nz-option>
							</nz-select>
						</nz-form-control>
					</nz-form-item>

					<nz-form-item>
						<nz-form-label [nzSm]="12" [nzXs]="24" nzRequired nzFor="modules_orientation">
							{{'modules_orientation' | translate}}
						</nz-form-label>
						<nz-form-control [nzSm]="12" [nzXs]="24">
							<nz-select formControlName="modules_orientation" id="modules_orientation"
								(ngModelChange)="updateModulesTableSettings()">
								<nz-option nzValue="portrait" nzLabel="{{'portait' | translate}}"></nz-option>
								<nz-option nzValue="landscape" nzLabel="{{'landscape' | translate}}"></nz-option>
							</nz-select>
						</nz-form-control>
					</nz-form-item>

					<nz-form-item>
						<nz-form-label [nzSm]="12" [nzXs]="24" nzRequired nzFor="module_type">
							{{'module_type' | translate}}
						</nz-form-label>
						<nz-form-control [nzSm]="12" [nzXs]="24">
							<nz-select formControlName="module_type" id="module_type"
								(ngModelChange)="updateModulesTableSettings()">
								<nz-option nzValue="60" nzLabel="60 {{'cells' | translate}}"></nz-option>
								<nz-option nzValue="72" nzLabel="72 {{'cells' | translate}}"></nz-option>
							</nz-select>
						</nz-form-control>
					</nz-form-item>

					<nz-form-item>
						<nz-form-label [nzSm]="12" [nzXs]="24" nzRequired nzFor="mounting_height">
							{{'mounting_height' | translate}} (b)
						</nz-form-label>
						<nz-form-control [nzSm]="12" [nzXs]="24">
							<nz-input-group [nzSuffix]="meters">
								<input nz-input formControlName="mounting_height" id="mounting_height"
									(ngModelChange)="updateResult()" />
							</nz-input-group>
							<nz-form-explain
								*ngIf="validateForm.get('mounting_height')?.dirty && validateForm.get('mounting_height')?.errors">
								{{'range_value_validation_message' | translate:{start: mountingHeightMin, end: mountingHeightMax} }}
							</nz-form-explain>
						</nz-form-control>
					</nz-form-item>

					<nz-form-item>
						<nz-form-label [nzSm]="12" [nzXs]="24" nzRequired nzFor="mounting_tilt">
							{{'mounting_tilt' | translate}} (&alpha;)
						</nz-form-label>
						<nz-form-control [nzSm]="12" [nzXs]="24">
							<nz-input-group [nzSuffix]="degrees">
								<input nz-input formControlName="mounting_tilt" id="mounting_tilt"
									(ngModelChange)="updateModulesTableSettings()" />
							</nz-input-group>
							<nz-form-explain
								*ngIf="validateForm.get('mounting_tilt')?.dirty && validateForm.get('mounting_tilt')?.errors">
								{{'range_value_validation_message' | translate:{start: mountingTiltMin, end: mountingTiltMax} }}
							</nz-form-explain>
						</nz-form-control>
					</nz-form-item>

					<nz-form-item>
						<nz-form-label [nzSm]="12" [nzXs]="24" nzRequired nzFor="mounting_spacing">
							{{'mounting_spacing.label' | translate}} (c)</nz-form-label>
						<nz-form-control [nzSm]="12" [nzXs]="24">
							<nz-input-group [nzSuffix]="meters">
								<input nz-input formControlName="mounting_spacing" id="mounting_spacing"
									(ngModelChange)="updateResult()" />
							</nz-input-group>
							<nz-form-explain
								*ngIf="validateForm.get('mounting_spacing')?.dirty && validateForm.get('mounting_spacing')?.errors">
								{{'mounting_spacing.validation_message' | translate:{moduleTableWidthProjected:moduleTableWidthProjected | number: '1.0-2', start:mountingSpacingMin, end:mountingSpacingMax} }}
							</nz-form-explain>
						</nz-form-control>
					</nz-form-item>

					<nz-form-item>
						<nz-form-label [nzSm]="12" [nzXs]="24" nzRequired nzFor="roof_covering_albedo">
							{{'roof_covering_albedo.label' | translate}}
						</nz-form-label>
						<nz-form-control [nzSm]="12" [nzXs]="24">
							<nz-select formControlName="roof_covering_albedo" id="roof_covering_albedo"
								(ngModelChange)="updateResult()" nzPlaceHolder="Roof covering">
								<nz-option nzValue="16"
									nzLabel="{{'roof_covering_albedo.weathered_mortar' | translate}} (16%)">
								</nz-option>
								<nz-option nzValue="23" nzLabel="{{'roof_covering_albedo.grass' | translate}} 23%">
								</nz-option>
								<nz-option nzValue="27"
									nzLabel="{{'roof_covering_albedo.light_gravel' | translate}} (27%)">
								</nz-option>
								<nz-option nzValue="32"
									nzLabel="{{'roof_covering_albedo.grey_corrugated_sheet' | translate}} (32%)">
								</nz-option>
								<nz-option nzValue="56"
									nzLabel="{{'roof_covering_albedo.white_corrugated_sheet' | translate}} (56%)">
								</nz-option>
								<nz-option nzValue="63"
									nzLabel="{{'roof_covering_albedo.white_painted_mortar' | translate}} (63%)">
								</nz-option>
								<nz-option nzValue="80"
									nzLabel="{{'roof_covering_albedo.white_waterproofing_sheet' | translate}} (80%)">
								</nz-option>
								<nz-option nzValue="82"
									nzLabel="{{'roof_covering_albedo.fresh_snow' | translate}} (82%)">
								</nz-option>
								<nz-option nzValue="" nzLabel="{{'roof_covering_albedo.custom' | translate}}">
								</nz-option>
							</nz-select>
						</nz-form-control>
					</nz-form-item>

					<nz-form-item *ngIf="albedoSelectValue === ''">
						<nz-form-label [nzSm]="12" [nzXs]="24" nzRequired nzFor="custom_albedo">
							{{'roof_covering_albedo.custom' | translate}}
						</nz-form-label>
						<nz-form-control [nzSm]="12" [nzXs]="24">
							<nz-input-group [nzSuffix]="percentage">
								<input nz-input formControlName="custom_albedo" (ngModelChange)="updateResult()" />
							</nz-input-group>
							<nz-form-explain
								*ngIf="validateForm.get('custom_albedo')?.dirty && validateForm.get('custom_albedo')?.errors">
								{{'range_value_validation_message' | translate:{start: albedoCustomMin, end: albedoCustomMax} }}
							</nz-form-explain>
						</nz-form-control>
					</nz-form-item>

					<nz-form-item>
						<nz-form-label [nzSm]="12" [nzXs]="24" nzFor="stc_power">{{'stc_power' | translate}}
						</nz-form-label>
						<nz-form-control [nzSm]="12" [nzXs]="24">
							<nz-input-group [nzSuffix]="watts">
								<input nz-input formControlName="stc_power" id="stc_power"
									(ngModelChange)="updateResult()" />
							</nz-input-group>
						</nz-form-control>
					</nz-form-item>

					<ng-template #percentage>%</ng-template>
					<ng-template #meters>{{'units.meters' | translate}}</ng-template>
					<ng-template #watts>{{'units.watts' | translate}}</ng-template>
					<ng-template #degrees>{{'units.degrees' | translate}}</ng-template>
				</form>
				<div nz-row>
					<img src="assets/img/params_explanation.jpg" width="100%">
				</div>
			</div>
			<div nz-col nzOffset="2" nzSpan="10">
				<div nz-row *ngIf="imgName">
					<img src="assets/img/{{imgName}}.png">
				</div>
				<div nz-row>
					<h2>Results</h2>
					<p>{{'results.boost' | translate}} : {{boost * 100 | number: '1.0-1'}} <span *ngIf="boost">%</span>
					</p>
					<p>{{'results.total_power' | translate}} :
						{{totalPowerOutput | number: '1.0-0'}} <span *ngIf="totalPowerOutput">Watts</span></p>
				</div>
				<div nz-row>
					<p>{{'bottom_text' | translate}}</p>
				</div>
			</div>
		</div>
	</nz-content>
	<nz-affix nzOffsetBottom="0">
		<nz-footer style="text-align: center;">© Copyright 2020 - Suncell<sup>®</sup> is a trademark of Suncell Energy SA</nz-footer>
	</nz-affix>
</nz-layout>