import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

const mountingHeightMin = 0.1;
const mountingHeightMax = 2;
const mountingTiltMin = 5;
const mountingTiltMax = 35;
const mountingSpacingMin = 2;
const mountingSpacingMax = 15;
const albedoCustomMin = 0;
const albedoCustomMax = 100;

const moduleBifaciality = 0.85;
const s = 0.95;
const a = 1.03;
const b = 8.69;
const c = 0.12;
const moduleWidth = 0.99;
const moduleLength60Cells = 1.65;
const moduleLength72Cells = 1.98;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.less'],
	encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
	title = 'bifacialboost';

	readonly mountingHeightMin = mountingHeightMin;
	readonly mountingHeightMax = mountingHeightMax;
	readonly mountingTiltMin = mountingTiltMin;
	readonly mountingTiltMax = mountingTiltMax;
	readonly mountingSpacingMin = mountingSpacingMin;
	readonly mountingSpacingMax = mountingSpacingMax;
	readonly albedoCustomMin = albedoCustomMin;
	readonly albedoCustomMax = albedoCustomMax;

	validateForm: FormGroup;
	imgName: string;
	moduleTableWidthProjected: number;
	albedoSelectValue: string;

	albedo: number;
	powerFactor = 1;
	boost: number;
	totalPowerOutput: number;

	updateModulesTableSettings(): void {
		this.imgName = this.validateForm.controls.modules_orientation.value + '_' + this.validateForm.controls.rows_count.value + '_rows';
		const moduleLength = this.validateForm.controls.module_type.value === '60' ? moduleLength60Cells : moduleLength72Cells;
		const moduleTableWidth = this.validateForm.controls.rows_count.value * (this.validateForm.controls.modules_orientation.value === 'landscape' ? moduleWidth : moduleLength);

		this.moduleTableWidthProjected = moduleTableWidth * Math.cos(this.validateForm.controls.mounting_tilt.value * Math.PI / 180);

		this.updateResult();
	}

	updateResult(): void {
		this.albedoSelectValue = this.validateForm.controls.roof_covering_albedo.value;
		if (this.albedoSelectValue) {
			this.albedo = +this.albedoSelectValue;
		} else {
			this.albedo = +this.validateForm.controls.custom_albedo.value;
		}

		const A = this.validateForm.controls.mounting_spacing.value / this.moduleTableWidthProjected;
		const H = this.validateForm.controls.mounting_height.value / this.moduleTableWidthProjected;

		this.boost = (this.albedo / 100) * moduleBifaciality * s * (a * (1 - 1 / Math.sqrt(A)) * (1 - Math.exp(-(b * H) / A)) + c * (1 - 1 / Math.pow(A, 4)));

		if (this.validateForm.controls.stc_power.value && this.boost) {
			this.totalPowerOutput = this.validateForm.controls.stc_power.value * (1 + this.boost);
		}
	}

	albedoSelectValidator = (control: FormControl): { [s: string]: boolean } => {
		if (control.value == null) {
			return { required: true };
		} else {
			return {};
		}
	}

	albedoCustomValidator = (control: FormControl): { [s: string]: boolean } => {
		if (this.albedoSelectValue === '') {
			return { required: true };
		} else {
			return {};
		}
	}

	tableWidthMountinSpacingValidator = (control: FormControl): { [s: string]: boolean } => {
		if (this.validateForm && this.moduleTableWidthProjected && this.validateForm.controls.mounting_spacing.value && this.moduleTableWidthProjected > +this.validateForm.controls.mounting_spacing.value) {
			return { error: true };
		} else {
			return {};
		}
	}

	getSelectableLanguages(): Array<string> {
		return this.translate.getLangs().filter(l => l !== this.translate.currentLang);
	}

	constructor(
    private fb: FormBuilder,
    public translate: TranslateService,
    private route: ActivatedRoute,
  ) {
    translate.addLangs(['de', 'fr', 'en']);
    const browserLang = translate.getBrowserLang();
    if (browserLang.match(/de|fr|en/)) {
      translate.setDefaultLang(browserLang);
    } else {
      translate.setDefaultLang('en');
    }
		translate.use(translate.getDefaultLang());
	}

	ngOnInit(): void {
		this.validateForm = this.fb.group({
			rows_count: ['2'],
			modules_orientation: ['portrait'],
			module_type: ['60'],
			mounting_height: ['1', [Validators.required, Validators.min(mountingHeightMin), Validators.max(mountingHeightMax)]],
			mounting_tilt: ['20', [Validators.required, Validators.min(mountingTiltMin), Validators.max(mountingTiltMax)]],
			mounting_spacing: ['7', [Validators.required, this.tableWidthMountinSpacingValidator, Validators.min(mountingSpacingMin), Validators.max(mountingSpacingMax)]],
			roof_covering_albedo: ['63', [this.albedoSelectValidator]],
			custom_albedo: [null, [Validators.pattern(/^[0-9]{0,2}(\.[0-9]{1,2})?$|^-?(albedoCustomMax)(\.[0]{1,2})?$/)]],
			stc_power: ['310'],
    });

		this.updateModulesTableSettings();

    this.route.queryParamMap.subscribe((queryParams: Params) => {
      const urlLang = queryParams.params['lang'];
      if (urlLang && urlLang.match(/de|fr|en/)) {
        this.translate.use(urlLang);
      }
    });
	}
}
